@tailwind base;
@tailwind components;
@tailwind utilities;

.prose h1 {
  @apply text-5xl lg:text-6xl !leading-tight;
}

.prose h2,
h1.h2 {
  @apply text-4xl lg:text-5xl !leading-tight;
}

.prose h3,
h2.h3,
.blog-post h2 {
  @apply text-3xl;
}

.prose h4,
h3.h4 {
  @apply text-2xl;
}

.prose h5,
h4.h5 {
  @apply text-lg;
}

.prose p,
.prose ul {
  @apply text-base leading-relaxed md:text-lg;
}

.blurb {
  @apply !text-base leading-relaxed;
}

.subheading {
  @apply !text-xl;
}

button[data-headlessui-state="open"] svg.fa-chevron-down {
  @apply transition-transform rotate-180;
}

button:not([data-headlessui-state="open"]) svg.fa-chevron-down {
  @apply transition-transform rotate-0;
}

dt {
  @apply font-semibold;
}

summary {
  cursor: pointer;
}

summary::-webkit-details-marker {
  @apply !hidden;
}

pre {
  @apply !p-0 !bg-transparent !border border-primary !rounded-xl !my-8;
}

pre code.hljs {
  @apply !bg-white dark:!bg-gray-900 !p-6 md:!p-8;
}

.prose
  :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before,
.prose
  :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  @apply !hidden;
}

.prose :where(code):not(.hljs) {
  @apply border border-primary rounded-md bg-secondary p-1 whitespace-nowrap mx-1 font-normal;
}

th {
  @apply text-left;
}

.attestation {
  @apply px-4 py-2 bg-secondary border border-primary rounded-full text-sm global-hover-transition hover:border-gray-400 hover:dark:border-gray-600 text-primary min-w-16;
}

.bg-primary {
  @apply bg-gray-100 dark:bg-black;
}

.bg-secondary {
  @apply bg-white dark:bg-gray-900;
}

.border-primary {
  @apply border-gray-300 dark:border-gray-700;
}

.btn {
  @apply flex items-center rounded-full border whitespace-nowrap focus:ring-0 focus:outline-none px-4 py-2;
}

.btn.primary {
  @apply border-transparent bg-purple-600 text-white hover:bg-purple-700 global-hover-transition;
}

.btn.secondary {
  @apply border-primary hover:border-gray-600 bg-primary global-hover-transition;
}

.btn.nav.secondary {
  @apply font-medium border-primary text-gray-700 dark:text-gray-400  hover:text-gray-700 dark:hover:text-gray-300 focus:ring-0 focus:outline-none;
}

.btn.nav {
  @apply font-medium border-transparent text-gray-700 dark:text-gray-400  hover:text-gray-700 dark:hover:text-gray-300 focus:ring-0 focus:outline-none;
}

.btn.nav.active {
  @apply border border-primary bg-white dark:bg-gray-900 text-gray-900 dark:text-white;
}

.btn.nav-mobile {
  @apply justify-center text-gray-700 dark:text-gray-400  hover:text-gray-700 dark:hover:text-gray-300;
}

.btn.nav-mobile.primary {
  @apply text-white;
}

.btn.nav-mobile.active {
  @apply bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-white border-primary;
}

.card-gap {
  @apply gap-6 lg:gap-8 xl:gap-16;
}

.blurb-gap {
  @apply gap-12 xl:gap-16;
}

.calendly-popup,
.calendly-inline-widget {
  @apply !h-[839px] xl:!h-[900px] rounded-xl overflow-clip;
}

.calendly-inline-widget iframe {
  color-scheme: light;
}

.calendly-spinner {
  @apply hidden;
}

.container {
  @apply max-w-screen-2xl mx-auto px-6 lg:px-8;
}
.divide-primary {
  @apply divide-gray-300 dark:divide-gray-700;
}

.eyebrow {
  @apply !text-base font-semibold mt-0 mb-6 text-secondary;
}

.four0four {
  font-size: 8em;
}

.global-hover-transition {
  @apply transition-colors duration-300 hover:ease-in-out;
}

.header-icon {
  @apply text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 focus:ring-0 focus:outline-none;
}

.header-icon {
  @apply h-6 w-6;
}

.icon {
  @apply h-5 w-5 fill-gray-500 dark:fill-gray-400;
}

.link {
  @apply underline underline-offset-4 hover:no-underline;
}

.mask {
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.mdx-embed {
  @apply mt-8 border border-primary round overflow-clip;
}

.nav.hover {
  @apply hover:text-gray-700 dark:hover:text-gray-300;
}

.prose {
  @apply max-w-none;
}

.prose img {
  @apply m-0;
}

.quote {
  @apply text-primary !text-2xl !leading-normal sm:!text-3xl sm:!leading-relaxed mt-0 mb-12;
}

.quote span {
  @apply border-b-2 border-purple-500 inline;
}

.round {
  @apply rounded-xl;
}

.section-container {
  @apply max-w-7xl px-4 lg:px-8 mx-auto w-full;
}

.section-first {
  @apply py-24 lg:py-36;
}

.section-sub {
  @apply pb-24 lg:pb-36;
}

.section-overflow {
  @apply lg:-mx-8 -mx-4;
}

.center .slick-list {
  @apply !bg-gray-100 dark:!bg-gray-900;
}

.center .slick-slide {
  @apply !flex !justify-center;
}

.slick-dots {
  @apply !z-10 !bottom-0;
}

.slick-dots li button {
  @apply hover:opacity-75;
}

.slick-dots li button:before {
  @apply text-primary opacity-25 hover:scale-125 duration-300 hover:ease-in-out;
}

.slick-dots li.slick-active button:before {
  @apply text-primary;
}

.slick-next {
  @apply !right-3;
}

.slick-prev {
  @apply !left-3 z-10;
}

.slick-slide img {
  @apply m-0 -mb-4;
}

.slick-slider {
  @apply my-8 overflow-clip;
}

.slick-slider.images .slick-list {
  @apply round border border-primary overflow-clip;
}

.slick-slider.images .slick-dots,
.slick-slider.testimonials .slick-dots {
  @apply static mt-2;
}

.slick-slider.images .slick-dots li button:before,
.slick-slider.testimonials .slick-dots li button:before {
  @apply text-[10px];
}

.slick-slider.images .slick-dots li.slick-active button:before,
.slick-slider.testimonials .slick-dots li.slick-active button:before {
  @apply text-[14px];
}

.slick-slider.testimonials {
  @apply border-none overflow-visible rounded-none;
}

.slick-slider.testimonials img {
  @apply m-0;
}

.sidenav .btn.nav {
  @apply border border-transparent justify-start whitespace-normal;
}

.sidenav .btn.nav.active {
  @apply border border-primary;
}

.text-primary {
  @apply text-[#111827] dark:text-white;
}

.text-secondary {
  @apply text-[#374151] dark:text-[#d1d5db];
}

.youtube {
  @apply w-full relative pt-[56.25%];
}

.zero-spacer {
  width: 130px;
}
